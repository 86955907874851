h1,
h2 {
  color: var(--text-on-background-main);
}

.other h2 {
  font-size: 18px;
  margin-bottom: 7px;
}

.other a {
  color: var(--text-on-background-secondary);
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

h1 {
  font-size: 24px;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  width: 100%;
  color: inherit;
  position: relative;
  line-height: 20px;
}

.editor-inner {
  background: var(--color-surface);
  position: relative;
}

.editor-input {
  resize: none;
  position: relative;
  font-size: inherit;
  tab-size: 1;
  outline: 0;
  background-color: var(--color-surface);
  padding: 10px;
  height: 100%;
}

.editor-placeholder {
  color: var(--text-on-background-main);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: var(--color-secondary);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: var(--text-on-background-secondary);
  text-decoration: none;
}

.editor-code {
  background-color: var(--color-secondary);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: var(--color-surface);
  left: 0;
  top: 0;
  border-right: 1px solid var(--color-border);
  padding: 8px;
  color: var(--text-on-background-secondary);
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: var(--text-on-background-main);
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: var(--text-on-background-main);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: var(--text-on-background-main);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: var(--text-on-background-main);
  border-left-color: var(--color-border);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  list-style-type: decimal;
  padding: 0;
  margin: 0;
}

.editor-list-ul {
  list-style-type: disc;
  padding: 0;
  margin: 0;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: var(--color-border);
}
