/* Default styles for unique */
.ag-theme-unique {
  --ag-active-color: var(--color-primary-cta);
  --ag-data-color: var(--color-on-background-main);
  --ag-background-color: var(--color-surface);
  --ag-header-column-separator-color: var(--color-background-variant);
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-height: 40px;
  --ag-cell-horizontal-border: solid var(--color-background-variant);
  --ag-row-border-width: 1px;
  --ag-row-border-color: var(--color-background-variant);
  --ag-input-focus-border-color: var(--color-primary-variant);
  --ag-cell-focus-border-color: var(--color-primary-variant);
  --ag-header-column-resize-handle-display: none;
  --ag-header-foreground-color: var(--color-on-control-dimmed);
  --ag-border-color: var(--color-background-variant);
  --ag-header-background-color: var(--color-background);
  --ag-wrapper-border-radius: 0;
  --ag-checkbox-checked-background-color: var(--color-primary-cta);
  --ag-checkbox-background-color: var(--color-surface);
  --ag-checkbox-checked-color: var(--color-primary-cta);
  --ag-checkbox-unchecked-color: var(--color-control);
}

.ag-theme-unique .ag-header .ag-header-cell-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ag-theme-unique .highlight-cells {
  background-color: #f2f8f8;
}

/* Remove default x-axis paddings inside cells */
.ag-theme-unique .custom-ag-cell {
  padding: 0;
  line-height: 1.5;
}

.ag-theme-unique .custom-lock-pinned {
  background-color: var(--color-background) !important;
  color: var(--color-on-control-dimmed) !important;
}

.ag-theme-unique .ag-menu {
  background-color: var(--color-surface);
  border: none;
  color: var(--color-on-background-main);
}

.ag-theme-unique .ag-large-text-input {
  padding: 0;
}

.ag-theme-unique .ag-side-buttons {
  width: 0;
}

.ag-theme-unique .ag-react-container {
  width: 100%;
}

.ag-theme-unique .ag-row-pinned {
  @apply !bg-background-variant !text-on-background-main font-bold;
}

.ag-row-striped {
  @apply bg-background;
}

.ag-hidden-row {
  @apply hidden;
}

.ag-theme-unique .ag-icon-filter {
  color: var(--color-on-surface);
}

/* Remove the search icon */
.ag-theme-unique .ag-input-wrapper::before {
  display: none !important;
}

/* Remove padding from the search input */
.ag-theme-unique input.ag-text-field-input {
  padding-left: 10px !important;
}

/* Set the height of the filter dropdown menu to auto */
.ag-theme-unique .ag-filter-menu .ag-set-filter-list {
  height: auto;
  padding-bottom: 10px;
}
